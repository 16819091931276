var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-choose" },
    [
      _c(
        "Button",
        {
          attrs: { icon: _vm.icon },
          on: {
            click: function ($event) {
              _vm.userModalVisible = true
            },
          },
        },
        [_vm._v(_vm._s(_vm.text))]
      ),
      _c("span", { staticClass: "clear", on: { click: _vm.clearSelectData } }, [
        _vm._v("清空已选"),
      ]),
      _c(
        "Collapse",
        { staticClass: "collapse", attrs: { simple: "" } },
        [
          _c("Panel", { attrs: { name: "1" } }, [
            _vm._v(" 已选择 "),
            _c("span", { staticClass: "select-count" }, [
              _vm._v(_vm._s(_vm.selectUsers.length)),
            ]),
            _vm._v(" 人 "),
            _c(
              "p",
              { attrs: { slot: "content" }, slot: "content" },
              _vm._l(_vm.selectUsers, function (item, i) {
                return _c(
                  "Tag",
                  {
                    key: i,
                    attrs: { name: item.id, color: "default", closable: "" },
                    on: { "on-close": _vm.handleCancelUser },
                  },
                  [
                    _c(
                      "Tooltip",
                      { attrs: { placement: "top", content: item.username } },
                      [_vm._v(_vm._s(item.nickname))]
                    ),
                  ],
                  1
                )
              }),
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "Drawer",
        {
          attrs: {
            title: "选择用户",
            closable: "",
            width: "800",
            draggable: "",
          },
          model: {
            value: _vm.userModalVisible,
            callback: function ($$v) {
              _vm.userModalVisible = $$v
            },
            expression: "userModalVisible",
          },
        },
        [
          _c(
            "Form",
            {
              ref: "searchUserForm",
              attrs: {
                model: _vm.searchUserForm,
                inline: "",
                "label-width": 55,
              },
            },
            [
              _c(
                "FormItem",
                { attrs: { label: "用户名", prop: "nickname" } },
                [
                  _c("Input", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      type: "text",
                      clearable: "",
                      placeholder: "请输入用户名",
                    },
                    model: {
                      value: _vm.searchUserForm.nickname,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchUserForm, "nickname", $$v)
                      },
                      expression: "searchUserForm.nickname",
                    },
                  }),
                ],
                1
              ),
              _c(
                "FormItem",
                { attrs: { label: "部门", prop: "department" } },
                [
                  _c("department-choose", {
                    ref: "dep",
                    staticStyle: { width: "200px" },
                    on: { "on-change": _vm.handleSelectDep },
                  }),
                ],
                1
              ),
              _c(
                "FormItem",
                { staticClass: "br", staticStyle: { "margin-left": "-35px" } },
                [
                  _c(
                    "Button",
                    {
                      attrs: { type: "primary", icon: "ios-search" },
                      on: { click: _vm.handleSearchUser },
                    },
                    [_vm._v("搜索")]
                  ),
                  _c("Button", { on: { click: _vm.handleResetUser } }, [
                    _vm._v("重置"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("Alert", { attrs: { "show-icon": "" } }, [
            _vm._v(" 已选择 "),
            _c("span", { staticClass: "select-count" }, [
              _vm._v(_vm._s(_vm.selectUsers.length)),
            ]),
            _vm._v(" 项 "),
            _c(
              "a",
              {
                staticStyle: { "margin-left": "10px" },
                on: { click: _vm.clearSelectData },
              },
              [_vm._v("清空已选")]
            ),
          ]),
          _c("Table", {
            staticStyle: { margin: "2vh 0" },
            attrs: {
              loading: _vm.userLoading,
              border: "",
              columns: _vm.userColumns,
              data: _vm.userData,
            },
          }),
          _c(
            "Row",
            { attrs: { type: "flex", justify: "end" } },
            [
              _c("Page", {
                attrs: {
                  current: _vm.searchUserForm.pageNumber,
                  total: _vm.totalUser,
                  "page-size": _vm.searchUserForm.pageSize,
                  "page-size-opts": [10, 20, 50],
                  size: "small",
                  "show-total": "",
                  "show-elevator": "",
                  "show-sizer": "",
                  transfer: "",
                },
                on: {
                  "on-change": _vm.changeUserPage,
                  "on-page-size-change": _vm.changeUserPageSize,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }